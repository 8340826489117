export const TableElementsBehaviour = {
  data() {
    return {
      isWidthSet: false
    }
  },
  methods: {
    StickyHeader(event) {
      const element = event.target;
      if (element.scrollTop > 47) {
        element.classList.add('sticky');
        element.querySelector('thead').style.marginTop = element.scrollTop + 'px';
      } else {
        element.classList.remove('sticky');
        element.querySelector('thead').style.top = '0px';
      }
      this.SetTdWidth(event.target.id);
    },
    SetTdWidth(element_id, reset = null) {
      if (reset === null && this.isWidthSet) return;
      const element = document.querySelector('#%1'.replace('%1', element_id));
      const theadChildren = element.querySelector('thead tr').children;
      const tbodyChildren = element.querySelector('tbody tr').children;
      for (let i = 0; i < tbodyChildren.length; i++) {
        const theadChild = theadChildren[i];
        const tbodyChild = tbodyChildren[i];
        theadChild.style.width = tbodyChild.offsetWidth + 'px';
      }
      this.isWidthSet = true;
    },
    SetBackground(event) {
      const table = event.target.closest('table');
      const dropdown = event.target.querySelector('ul');
      const nested = Array.from(dropdown.querySelectorAll('ul')).slice(-1)[0];
      const footer = document.querySelector('.list-overflow-scrollbar.bottom, .grid-pagination.sticky');
      let tableBottom = table.getBoundingClientRect().bottom;
      let dropdownBottom = dropdown.getBoundingClientRect().bottom;
      if (nested) dropdownBottom = nested.getBoundingClientRect().bottom;
      if (footer) tableBottom = footer.getBoundingClientRect().top;
      dropdown.classList[dropdownBottom > tableBottom ? 'add' : 'remove']('outside');
      event.target.closest('tr').classList.toggle('hover');
    }
  }
};
