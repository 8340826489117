<template>
  <transition name="fade">
    <div class="modal" v-if="value" @mousedown="ValidateModalState">
      <div class="modal-wrapper">
        <div class="modal-wrapper__top">
          <p class="title pre-wrap" v-html="title" />
          <a @click.prevent="ValidateModalState" class="close-modal" href="#">
            <div></div>
            <div></div>
          </a>
        </div>
        <div class="modal-wrapper__content">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'Modal',
    data() {
      return {}
    },
    props: {
      value: {
        required: true
      },
      modal: {
        required: true
      },
      title: {
        required: true
      }
    },
    methods: {
      ValidateModalState(event) {
        if (event?.target?.classList.contains('modal') 
        || event?.target?.classList.contains('close-modal') 
        || event?.target?.parentNode?.classList.contains('close-modal')) {
          this.$eventHub.$emit('CloseModal', this.modal);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
