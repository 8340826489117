<template>
  <div class="users-wrapper__groups">
    <div class="grid-wrapper">
      <div class="col col-12">
        <div class="grid-info">
          <span class="grid-heading">
            <h1>{{ $t('Groups') }}</h1>
            <span class="entries">{{ NumberFormat(list.items.length) }} {{ $t(`entr${list.items.length == 1 ? 'y' : 'ies'}`) }}</span>
          </span>
          <div class="grid-actions">
            <a class="button" @click.prevent="CreateGroup" href="">{{ $t('Create') }}</a>
          </div>
          <div class="grid-search">
            <input type="text" @keyup.enter="SearchGroups(), $event.target.blur()" v-model="list.query" :placeholder="$t('Search by name')">
          </div>
        </div>
        <table class="group-list table odd-even">
          <thead>
            <tr class="group-list__actions">
              <th class="id sort" :class="list.sort.column == 'id' && list.sort.by == 'number' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'number', 'id', 'id')">
                <span>{{ $t('ID') }}</span>
              </th>
              <th class="group sort" :class="list.sort.column == 'name' && list.sort.by == 'string' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'string', 'name', 'name')">
                <span>{{ $t('Group') }}</span>
              </th>
              <th class="edit"></th>
            </tr>
          </thead>
          <tbody>
            <tr :data-id="item.id" class="group-list__row clickable" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" :key="index" v-for="(item, index) in list.items">
              <td class="id">{{ item.id }}</td>
              <td class="name">{{ item.name }}</td>
              <td @mouseenter="SetBackground" @mouseleave="SetBackground" class="actions">
                <a @click.prevent="" href="" class="icon dots">
                  <ul class="item-actions">
                    <li><a @click.prevent="ViewGroup(item.id)" href="">{{ $t('View') }}</a></li>
                    <li><a @click.prevent="EditGroup(item.id)" href="">{{ $t('Edit') }}</a></li>
                    <li><a @click.prevent="DeleteGroup(item.id)" href="" class="error">{{ $t('Delete') }}</a></li>
                  </ul>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Modal modal="create_group" :value="modal.create_group.open" :title="$t('New group')">
      <div id="create-group">
        <form class="form" @submit.prevent="CreateGroupSubmit" autocomplete="off">
          <div class="form-group" style="flex: 1 0 auto;">
            <label for="name" class="required" style="margin-bottom: 60px;">
              <span class="span">{{ $t('Name') }}</span>
              <input @input="ValidateInput" class="text" type="text" :placeholder="$t('Name')" name="name" id="name" ref="name" data-type="create" @blur="TrimInput" @keydown.enter="$event.target.blur()" v-model="modal.create_group.data.name">
            </label>
          </div>
          <div class="filter-wrapper">
            <label class="label-text" for="">Status</label>
            <div class="checkbox-toggle">
              <input
              type="checkbox"
              @change="FilterUsers('Users', users)"
              />
              <span class="toggle" />
            </div>
          </div>
          <div class="form-group" style="padding-top: 0; flex: 1 1 auto;">
            <table class="user-list table odd-even">
              <thead>
                <tr class="user-list__actions">
                  <th class="id">
                    <div style="display: flex; width: 100%; transform: translateY(-3px);">
                      <label class="checkbox" for="user_all" style="width: auto; cursor: pointer; margin: 0;">
                        <span class="span">ID</span>
                        <input type="checkbox" id="user_all" name="user_all" @input="ToggleSelectAll" checked>
                        <svg viewBox="0 0 21 21"><polyline points="5 10.75 8.5 14.25 16 6" /></svg>
                      </label>
                    </div>
                  </th>
                  <th>
                    <div class="th-wrapper">
                      <span @click="SortUsers('User', users), sortBy.sort = 'user'">{{ $t('User') }}</span>
                      <svg v-if="sortBy.sort == 'user' && sortBy.user" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/></svg>
                      <svg v-if="sortBy.sort == 'user' && !sortBy.user" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>
                    </div>
                  </th>
                  <th>
                    <div class="th-wrapper">
                      <span @click="SortUsers('Login', users), sortBy.sort = 'login'">{{ $t('Login') }}</span>
                      <svg v-if="sortBy.sort == 'login' && sortBy.login" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/></svg>
                      <svg v-if="sortBy.sort == 'login' && !sortBy.login" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>
                    </div>
                  </th>
                  <th>
                    <div class="th-wrapper">
                      <span @click="SortUsers('Start Date', users), sortBy.sort = 'start_date'">{{ $t('Start Date') }}</span>
                      <svg v-if="sortBy.sort == 'start_date' && sortBy.start_date" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/></svg>
                      <svg v-if="sortBy.sort == 'start_date' && !sortBy.start_date" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>
                    </div>
                  </th>
                  <th>
                    <div class="th-wrapper">
                      <span @click="SortUsers('Status', users), sortBy.sort = 'status'">{{ $t('Status') }}</span>
                      <svg v-if="sortBy.sort == 'status' && sortBy.status" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/></svg>
                      <svg v-if="sortBy.sort == 'status' && !sortBy.status" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="user-list__row" :key="user.id" v-for="user in users">
                  <td class="id">
                    <div style="display: flex; width: 100%;">
                      <label class="checkbox" :for="user.id" style="width: auto; cursor: pointer; margin: 0;">
                        <span class="span">{{ user.id }}</span>
                        <input type="checkbox" :id="user.id" name="user" checked>
                        <svg viewBox="0 0 21 21"><polyline points="5 10.75 8.5 14.25 16 6" /></svg>
                      </label>
                    </div>
                  </td>
                  <td class="name">{{ TitleCase(user.name) }}</td>
                  <td class="login"> {{ user.last_login && DateFormat(user.last_login) }} </td>
                  <td class="start-date"> {{ user.employment_day && DateOnly(user.employment_day) }} </td>
                  <td class="status">
                    <div class="flex-row state" :class="[user.disabled ? 'negative' : 'positive']">
                      {{ $t((user.disabled ? 'Ina' : 'A') + 'ctive') }}
                    </div>  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-action">
            <input class="submit done" style="min-width: 115px;" type="submit" :value="$t('Save')">
          </div>
        </form>
      </div>
    </Modal>

    <Modal modal="view_group" :value="modal.view_group.open" :title="`${$t('Group')} #${group.id}: ${group.name}`">
      <div id="view-group" style="padding-top: 4%;">
        <div class="filter-wrapper">
            <label class="label-text" for="">Status</label>
            <div class="checkbox-toggle">
              <input
              type="checkbox"
              @change="FilterUsers('Users-View', modal.view_group.data.users)"
              />
              <span class="toggle" />
            </div>
          </div>
        <div class="form-group scrollable" style="padding-top: 0; flex: 1 1 auto;">
          <table class="user-list table odd-even">
            <thead>
              <tr class="user-list__actions">
                <th class="id">
                  <span>{{ $t('ID') }}</span>
                </th>
                <th>
                  <div class="th-wrapper">
                    <span @click="SortUsers('User', modal.view_group.data.users), sortBy.sort = 'user'">{{ $t('User') }}</span>
                    <svg v-if="sortBy.sort == 'user' && sortBy.user" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/></svg>
                    <svg v-if="sortBy.sort == 'user' && !sortBy.user" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>
                  </div>
                </th>
                <th>
                  <div class="th-wrapper">
                    <span @click="SortUsers('Login', modal.view_group.data.users), sortBy.sort = 'login'">{{ $t('Login') }}</span>
                    <svg v-if="sortBy.sort == 'login' && sortBy.login" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/></svg>
                    <svg v-if="sortBy.sort == 'login' && !sortBy.login" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>
                  </div>
                </th>
                <th>
                  <div class="th-wrapper">
                    <span @click="SortUsers('Start Date', modal.view_group.data.users), sortBy.sort = 'start_date'">{{ $t('Start Date') }}</span>
                    <svg v-if="sortBy.sort == 'start_date' && sortBy.start_date" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/></svg>
                    <svg v-if="sortBy.sort == 'start_date' && !sortBy.start_date" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>
                  </div>
                </th>
                <th>
                  <div class="th-wrapper">
                    <span @click="SortUsers('Status', modal.view_group.data.users), sortBy.sort = 'status'">{{ $t('Status') }}</span>
                    <svg v-if="sortBy.sort == 'status' && sortBy.status" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/></svg>
                    <svg v-if="sortBy.sort == 'status' && !sortBy.status" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="user-list__row" :key="user.id" v-for="user in modal.view_group.data.users">
                <td class="id">{{ user.id }}</td>
                <td class="name">{{ TitleCase(user.name) }}</td>
                <td class="login"> {{ user.last_login && DateFormat(user.last_login) }} </td>
                <td class="start-date"> {{ user.employment_day && DateOnly(user.employment_day) }} </td>
                <td class="status">
                  <div class="flex-row state" :class="[user.disabled ? 'negative' : 'positive']">
                    {{ $t((user.disabled ? 'Ina' : 'A') + 'ctive') }}
                  </div>  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Modal>

    <Modal modal="edit_group" :value="modal.edit_group.open" :title="`${$t('Group')} #${group.id}`">
      <div id="edit-group">
        <form class="form" @submit.prevent="EditGroupSubmit" autocomplete="off">
          <div class="form-group" style="flex: 1 0 auto;">
            <label for="name" :class="['required', {filled: modal.edit_group.data.name}]" style="margin-bottom: 60px;">
              <span :class="['span', {show: modal.edit_group.data.name}]">{{ $t('Name') }}</span>
              <input @input="ValidateInput" class="text" type="text" :placeholder="$t('Name')" name="name" id="name" ref="name" data-type="edit" @blur="TrimInput" @keydown.enter="$event.target.blur()" v-model="modal.edit_group.data.name">
            </label>
          </div>
          <div class="filter-wrapper">
            <label class="label-text" for="">Status</label>
            <div class="checkbox-toggle">
              <input
              type="checkbox"
              @change="FilterUsers('Users-Edit', modal.edit_group.data.users)"
              />
              <span class="toggle" />
            </div>
          </div>
          <div class="form-group" style="padding-top: 0; flex: 1 1 auto;">
            <table class="user-list table odd-even">
              <thead>
                <tr class="user-list__actions">
                  <th class="id">
                    <div style="display: flex; width: 100%; transform: translateY(-3px);">
                      <label class="checkbox" for="user_all" style="width: auto; cursor: pointer; margin: 0;">
                        <span class="span">ID</span>
                        <input type="checkbox" id="user_all" name="user_all" @input="ToggleSelectAll" :checked="modal.edit_group.data.all">
                        <svg viewBox="0 0 21 21"><polyline points="5 10.75 8.5 14.25 16 6" /></svg>
                      </label>
                    </div>
                  </th>
                  <th>
                    <div class="th-wrapper">
                      <span @click="SortUsers('User', modal.edit_group.data.users), sortBy.sort = 'user'">{{ $t('User') }}</span>
                      <svg v-if="sortBy.sort == 'user' && sortBy.user" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/></svg>
                      <svg v-if="sortBy.sort == 'user' && !sortBy.user" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>
                    </div>
                  </th>
                  <th>
                    <div class="th-wrapper">
                      <span @click="SortUsers('Login', modal.edit_group.data.users), sortBy.sort = 'login'">{{ $t('Login') }}</span>
                      <svg v-if="sortBy.sort == 'login' && sortBy.login" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/></svg>
                      <svg v-if="sortBy.sort == 'login' && !sortBy.login" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>
                    </div>
                  </th>
                  <th>
                    <div class="th-wrapper">
                      <span @click="SortUsers('Start Date', modal.edit_group.data.users), sortBy.sort = 'start_date'">{{ $t('Start Date') }}</span>
                      <svg v-if="sortBy.sort == 'start_date' && sortBy.start_date" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/></svg>
                      <svg v-if="sortBy.sort == 'start_date' && !sortBy.start_date" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>
                    </div>
                  </th>
                  <th>
                    <div class="th-wrapper">
                      <span @click="SortUsers('Status', modal.edit_group.data.users), sortBy.sort = 'status'">{{ $t('Status') }}</span>
                      <svg v-if="sortBy.sort == 'status' && sortBy.status" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/></svg>
                      <svg v-if="sortBy.sort == 'status' && !sortBy.status" width="1.15rem" height="1.15rem" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="user-list__row" :key="user.id" v-for="user in modal.edit_group.data.users">
                  <td class="id">
                    <div style="display: flex; width: 100%;">
                      <label class="checkbox" :for="user.id" style="width: auto; cursor: pointer; margin: 0;">
                        <span class="span">{{ user.id }}</span>
                        <input type="checkbox" :id="user.id" name="user" :checked="user.checked">
                        <svg viewBox="0 0 21 21"><polyline points="5 10.75 8.5 14.25 16 6" /></svg>
                      </label>
                    </div>
                  </td>
                  <td class="name">{{ TitleCase(user.name) }}</td>
                  <td class="login"> {{ user.last_login && DateFormat(user.last_login) }} </td>
                  <td class="start-date"> {{ user.employment_day && DateOnly(user.employment_day) }} </td>
                  <td class="status">
                    <div class="flex-row state" :class="[user.disabled ? 'negative' : 'positive']">
                      {{ $t((user.disabled ? 'Ina' : 'A') + 'ctive') }}
                    </div>  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-action">
            <input class="submit done" style="min-width: 115px;" type="submit" :value="$t('Save')">
            <a class="button red" style="min-width: 115px;" @click.prevent="DeleteGroup(group.id)" href="">{{ $t('Delete') }}</a>
          </div>
        </form>
      </div>
    </Modal>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
  import { FormElementsBehaviour }  from '@/mixins/FormElementsBehaviour';
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { Tool }                   from '@/helpers/Tool';
  import { BPA }                    from '@/helpers/BPA';
  import Modal                      from '@/components/snippets/Modal';

  export default {
    name: 'UsersGroups',
    mixins: [
      FormElementsBehaviour, 
      TableElementsBehaviour, 
      Tool,
      BPA
    ],
    components: {
      Modal
    },
     data() {
      return {
        loading: true,
        permissions: {},
        clickables: [],
        cached: {},
        group: {},
        users: [],
        list: {
          query: '',
          items: [],
          sort: {
            column: 'id',
            prop: 'id',
            dir: 'desc',
            by: 'number'
          }
        },
        modal: {
          create_group: {
            open: false,
            data: {
              name: ''
            }
          },
          view_group: {
            open: false,
            data: {}
          },
          edit_group: {
            open: false,
            data: {}
          }
        },
        sortBy: {
          sort: '',
          user: false,
          status: false,
          login: false,
          start_date: false,
        },
        filterBy: {
          active: false
        },
        beforeFilterUsers: []
      };
    },
    created() {},
    async mounted() {
      this.permissions = BPA.api.Permissions('GET');
      this.$eventHub.$on('CloseModal', (modal_name) => {
        if (this.modal[modal_name]) this.modal[modal_name].open = false;
        if (modal_name == 'view_group') {
          this.group = {};
          this.cached.group = {};
          BPA.cache.session({name: this.$options.name, set: {group: {}}});
          for (let i = 0; i < this.clickables.length; i++) {
            if (this.clickables[i].classList.contains('selected')) {
              this.clickables[i].classList.remove('selected');
            }
          }
        }
      });
      this.cached = BPA.cache.local({name: this.$options.name, get: 'list'});
      for (let key of Object.keys(this.cached)) this[key] = {...this[key], ...this.cached[key]};
      this.cached = {...this.cached, ...BPA.cache.session({name: this.$options.name, get: 'group'})};
      await this.SearchGroups();
      this.loading = false;
    },
    methods: {
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      Capitalize(string) {
        return Tool.Capitalize(string);
      },
      TitleCase(string) {
        return Tool.TitleCase(string);
      },
      Alphabetize(list) {
        return list.sort((a, b) => {
          a = a.name; b = b.name;
          return a < b ? -1 : a > b ? 1 : 0;
        });
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      DateFormat(date) {
          return Tool.DateFormat(date)
      },
      DateOnly(date) {
        date = date + ' 00:00:00';
        date = Tool.DateFormat(date).replace(Tool.TimeOnly(date), '');
        return date.replace(/,(?=[^,]*$)/, '');

      },
      CountryName(country_code) {
        return Tool.CountryName(country_code);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      CurrencyFormat(number) {
        return Tool.CurrencyFormat(number);
      },
      async SearchGroups(query = '') {
        query = query || this.list.query;
        const sort = this.list.sort;
        const regex = new RegExp(query, 'ig');
        this.loading = true;
        const groups = await this.GetUserGroupList();
        this.loading = false;
        this.list.items = groups.filter(item => regex.test(item.name));
        this.Sort(this.list, 'items', sort.by, sort.prop, sort.column, sort.dir);
        BPA.cache.local({name: this.$options.name, set: {list: this.list}});
        this.$nextTick().then(() => {
          this.clickables = document.querySelectorAll('.clickable');
          if (this.cached.group && Object.keys(this.cached.group).length) {
            this.ViewGroup(this.cached.group.id);
          }
        });
      },
      async CreateGroup() {
        this.filterBy.active = false
        this.sortBy = this.ResetSortBy()
        if (!this.users.length) {
          this.loading = true;
          await this.GetUsers();
          this.loading = false;
        }
        this.modal.create_group.data.name = '';
        this.modal.create_group.open = true;
        this.$nextTick().then(() => {
          this.$refs.name.focus();
        });
      },
      async CreateGroupSubmit() {
        const group_name = this.modal.create_group.data.name;
        if (!group_name) {
          const input = this.$refs.name;
          this.Error(input, 'Group name must be set');
          return input.focus();
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, create it',
          disapprove: 'No',
          message: 'Creates the current group',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          const group_id = await this.CreateUserGroup(group_name);
          if (!group_id) return this.loading = false;
          const user_ids = this.Users(true).map(input => input.id);
          for (let user_id of user_ids) await this.AddUserToGroup({group_id, user_id});
          this.modal.create_group.open = false;
          await this.SearchGroups();
          this.loading = false;
          this.$eventHub.$emit('ShowMessages', {
            message: 'Group successfully created',
            type: 'success',
            hide: 2000
          });
        });
      },
      async ViewGroup(group_id) {
        this.filterBy.active = false
        this.sortBy = this.ResetSortBy()
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].dataset.id == group_id) {
            this.clickables[i].classList.add('selected');
            if (this.cached.group && Object.keys(this.cached.group).length) {
              this.clickables[i].scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
              });
            }
            break;
          }
        }
        this.loading = true;
        await this.GetGroup(group_id);
        const group = this.CloneObject(this.group);
        group.users = group.res_user_ids;
        this.modal.view_group.data = group;
        this.loading = false;
        this.modal.view_group.open = true;
        BPA.cache.session({name: this.$options.name, set: {group}});
      },
      async EditGroup(group_id) {
        this.filterBy.active = false
        this.sortBy = this.ResetSortBy()
        if (!this.users.length) {
          this.loading = true;
          await this.GetUsers();
        }
        await this.GetGroup(group_id);
        const group = this.CloneObject(this.group);
        const users = group.res_user_ids;
        const group_users = [];
        for (let user of this.users) {
          const checked = !!users.find(o => o.id == user.id);
          group_users.push({...user, ...{checked}});
        }
        group.users = group_users;
        this.modal.edit_group.data = group;
        this.loading = false;
        this.modal.edit_group.open = true;
      },
      async EditGroupSubmit() {
        const input = this.$refs.name;
        const group = this.modal.edit_group.data;
        const res_user_ids = group.res_user_ids.map(user => user.id);
        const user_ids = this.Users(true).map(input => input.id);
        const users_to = {add: [], remove: []};
        const group_id = group.id;
        const update = {
          name: false,
          users: false,
          possible: false
        }
        for (let user of this.users) {
          const found_in = {
            res_user_ids: res_user_ids.some(id => id == user.id),
            user_ids: user_ids.some(id => id == user.id)
          }
          if (!found_in.res_user_ids && found_in.user_ids) {
            users_to.add.push(user.id);
          }
          if (found_in.res_user_ids && !found_in.user_ids) {
            users_to.remove.push(user.id);
          }
        }
        if (users_to.add.length || users_to.remove.length) {
          update.possible = true;
          update.users = true;
        }
        if (!group.name) {
          this.Error(input, 'Group name must be set');
          return input.focus();
        }
        if (group.name != this.group.name) {
          update.possible = true;
          update.name = true;
        }
        if (!update.possible) {
          return this.modal.edit_group.open = false;
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, update it',
          disapprove: 'No',
          message: 'Updates the current group',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          if (update.name) {
            await this.SetGroupName(group.name);
          }
          if (update.users) {
            for (let user_id of users_to.add) {
              await this.AddUserToGroup({group_id, user_id});
            }
            for (let user_id of users_to.remove) {
              await this.RemoveUserFromGroup({group_id, user_id});
            }
          }
          this.modal.edit_group.open = false;
          await this.SearchGroups();
          this.loading = false;
          this.$eventHub.$emit('ShowMessages', {
            message: 'Group successfully updated',
            type: 'success',
            hide: 2000
          });
        });
      },
      async DeleteGroup(group_id) {
        if (!group_id) return console.error('Missing group ID');
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, delete it',
          disapprove: 'No',
          message: 'Deletes the current group',
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          const deleted = await this.DeleteUserGroup(group_id);
          if (!deleted) return this.loading = false;
          this.$eventHub.$emit('ShowMessages', {
            message: 'Group successfully deleted',
            type: 'success',
            hide: 2000
          });
          await this.SearchGroups();
          this.loading = false;
        });
      },
      async GetUsers() {
        this.users = await this.GetUserGroupUserList();
      },
      async GetGroup(group_id) {
        this.group = await this.GetUserGroup(group_id);
      },
      async SetGroupName(group_name) {
        return await this.UpdateUserGroupName({group_id: this.group.id, group_name});
      },
      async GetUserGroupList() {
        return await new Promise((resolve, reject) => {
          BPA.api.GetUserGroupList().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetUserGroupUserList() {
        return await new Promise((resolve, reject) => {
          BPA.api.GetUserGroupUserList().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async CreateUserGroup(group_name) {
        return await new Promise((resolve, reject) => {
          if (!group_name) return reject();
          BPA.api.CreateUserGroup(group_name).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetUserGroup(group_id) {
        return await new Promise((resolve, reject) => {
          if (!group_id) return reject();
          BPA.api.GetUserGroup(group_id).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async UpdateUserGroupName(params = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(params).length) return reject();
          BPA.api.UpdateUserGroupName(params).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async DeleteUserGroup(group_id) {
        return await new Promise((resolve, reject) => {
          if (!group_id) return reject();
          BPA.api.DeleteUserGroup(group_id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async AddUserToGroup(params = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(params).length) return reject();
          BPA.api.AddUserToGroup(params).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async RemoveUserFromGroup(params = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(params).length) return reject();
          BPA.api.RemoveUserFromGroup(params).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      Sort(obj, list, by, prop = '', col = '', dir) {
        const sort = obj.sort;
        const items = obj[list];
        const direction = (a, b) => {
          if (sort.dir == 'asc') {
            return a < b ? -1 : a > b ? 1 : 0;
          } else {
            return b < a ? -1 : b > a ? 1 : 0;
          }
        }
        if (sort.by == by) {
          sort.dir = sort.dir == 'desc' ? 'asc' : 'desc';
        }
        if (dir) sort.dir = dir;
        sort.by = by;
        sort.prop = prop;
        sort.column = col;
        items.sort((a, b) => {
          switch (by) {
            case 'date': 
              a = new Date(a[prop]);
              b = new Date(b[prop]);
              break;
            case 'number':
              a = parseInt(a[prop]);
              b = parseInt(b[prop]);
              break;
            case 'string':
              a = String(a[prop]).toUpperCase();
              b = String(b[prop]).toUpperCase();
              break;
            default:
              a = a[prop];
              b = b[prop];
          }
          return direction(a, b);
        });
      },
      TrimInput(event) {
        const input = event.target;
        const modal_key = input.dataset.type + '_group';
        let value = this.Capitalize(String(input.value).replace(/\s\s+/g, ' ').trim());
        this.modal[modal_key].data.name = value;
        input.value = value;
      },
      ToggleSelectAll(event) {
        for (let e of this.Users()) e.checked = event.target.checked;
      },
      Users(checked) {
        return Array.from(document.querySelectorAll(`[type="checkbox"]${checked ? ':checked' : ''}:not(#user_all)`));
      },
      ClickOpen(e) {
        if (!e) return;
        let row = {};
        let elm = e.target;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const filter = ['actions', 'icon dots'];
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.localName == 'a') return;
        if (filter.some(x => new RegExp(x).test(elm.className))) return;
        if (e.type == 'mousedown') {
          mousedown(e.which == 1);
        }
        if (e.type == 'mousemove') {
          mousedown(false);
        }
        if (e.type == 'mouseup') {
          if (mousedown()) {
            this.ViewGroup(row.dataset.id);
          }
          mousedown(false);
        }
      },
      SortUsers(sortBy, users) {
        switch (sortBy) {
          case 'User':
            this.sortBy.user == false ? users = users.sort((a, b) => a.name.localeCompare(b.name)) : users = users.sort((a, b) => b.name.localeCompare(a.name))
            this.sortBy.user = !this.sortBy.user
            break;
          case 'Status':
            this.sortBy.status == false ? users = users.sort((a, b) => {
              return (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1 
            }) :
            users = users.sort((a, b) => {
              return (a.disabled === b.disabled) ? 0 : a.disabled ? -1 : 1 
            })
            this.sortBy.status = !this.sortBy.status
          break;
          case 'Login':
            this.sortBy.login == false ? users = users.sort((a, b) => {
              return new Date(a.last_login).getTime() - new Date(b.last_login).getTime()
            }) : users = users.sort((a, b) => {
              return new Date(b.last_login).getTime() - new Date(a.last_login).getTime()
            })
            this.sortBy.login = !this.sortBy.login
          break;
          case 'Start Date':
            this.sortBy.start_date == false ? users = users.sort((a, b) => {
              return new Date(a.employment_day).getTime() - new Date(b.employment_day).getTime()
            }) : users = users.sort((a, b) => {
              return new Date(b.employment_day).getTime() - new Date(a.employment_day).getTime()
            })
            this.sortBy.start_date = !this.sortBy.start_date
          break;
          default: 
          break;
        }
      },
      FilterUsers(filterBy, users) {
        if(this.filterBy.active == false) {
          this.beforeFilterUsers = this.CloneObject(users)
          
          users = users.filter((user) => {
            if(user.disabled == false)
            {
              return user
            }
          }) 
          
          switch(filterBy) {
            case 'Users':
              this.users = users
              break;
            case 'Users-View':
              this.modal.view_group.data.users = users
              break;
            case 'Users-Edit':
              this.modal.edit_group.data.users = users
              break;
            default:
              break;
          }

          this.filterBy.active = true
        } else {
          switch(filterBy) {
            case 'Users':
              this.users = this.beforeFilterUsers
              break;
            case 'Users-View':
              this.modal.view_group.data.users = this.beforeFilterUsers
            break;
            case 'Users-Edit':
              this.modal.edit_group.data.users = this.beforeFilterUsers
              break;
            default:
              break;
            }
            this.filterBy.active = false
         }
      },
      ResetSortBy() {
        return {
          sort: '',
          user: false,
          status: false,
          login: false,
          start_date: false,
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/style/variables/colors';
  @import '../../../assets/style/variables/icons';

.form-action {
  & > * + * {
    margin-left: 20px;
  }
}
.th-wrapper {
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
}
.filter-wrapper {
  margin-bottom: 10px;
}

</style>
